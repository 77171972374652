.darkBackGround {
  width: 100%;
  height: 100%;
  background-color: #1b1c1b;
}

.redCross {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.gameContainer {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
}

.roomContainer {
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-height: 100vh;
  min-width: calc(100vh*1.55);
  background-size: contain;
}

.backgroundImg {
  max-height: 100%;
  max-width: 100%;
}

.wrongCode {
  position: relative;
  top: 35%;
  color: #c00000;
  font-size: 21px;
  font-weight: 500;
  text-transform: uppercase;
}

.startBtn {
  font-size: 35px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: 800;
  padding: 1em;
}

.arrowBack {
  position: absolute;
  width: 200px;
  left: 50%;
  top: 90%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.timer {
  position: absolute;
  top: 4.5%;
  left: 48%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2000;
  background-image: url("../assets/timer.png");
  background-position: center;
  width: 130px;
  height: 57px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.timer .timerContent {
  margin: 15.5px 0 0 38px;
  font-size: 17px;
  font-weight: 700;
  color: white;
}

.modalPoeme {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60%;
  height: 95%;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
}

.modalPoeme .imgModal {
  max-width: 70%;
  max-height: 70%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modalPoeme .poemeModal {
  height: 97%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modalPoeme .closeModal {
  cursor: pointer;
  margin: 0.5em;
  font-size: 25px;
  font-weight: 600;
  float: right;
  width: 20 !important;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
}

.modal .imgModal {
  max-width: 70%;
  max-height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal .valiseModal {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal .pcImgModal {
  width: 97%;
  position: relative;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal .closeModal {
  cursor: pointer;
  margin: 0.5em;
  font-size: 25px;
  font-weight: 600;
  float: right;
  width: 20 !important;
}

.sideBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #d4d4d4;
  padding-top: 1em;
  z-index: 10;
  padding: 0.5em;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sideBar .btnMaisonContainer {
  border-radius: 20px;
  text-align: center;
  font-weight: 700;
  width: 13vh;
  margin: 0 auto;
}

.sideBar .btnMaisonContainer img {
  width: 100%;
}

.sideBar .loupeAddictionBarre {
  text-align: center;
}

.sideBar .loupeAddictionBarre img {
  width: 8vh;
}

.sideBar .loupeAddictionBarre .titleAddiction {
  color: #c00000;
  font-weight: 700;
  font-size: 2.5vh;
}

.sideBar .photoAddictionContainer {
  height: 9vh;
  width: 13vh;
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(#d1d1d1));
  background: linear-gradient(white, #d1d1d1);
  border: 1.5px solid #c00000;
  border-radius: 10px;
  text-align: center;
  margin: 0.3em auto;
}

.sideBar .photoAddictionContainer img {
  width: 80%;
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transform: translateY(9%);
          transform: translateY(9%);
}

.sideBar .photoAddictionContainer .displayNone {
  display: none;
}

.onClick {
  cursor: pointer;
}

.BtnMaisonRouge {
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s;
  -webkit-animation-name: clignoter;
          animation-name: clignoter;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-transition: none;
  transition: none;
}

@-webkit-keyframes clignoter {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes clignoter {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
