
.entreeContainer{
    position: relative;
    background-image: url('../../assets/room/entree/entree.jpg');

}

.doorEntreeToSalon{
    width: 15.15%;
    height: 35.19%;
    position: absolute;
    top: 39.07%;
    left: 21.84%;
}

.doorEntreeToPalier{
    position: absolute;
    top: 0%;
    right: 6.5%;
    width: 31.7%;
    height: 100%;
    transform: skew(13deg);
    z-index: 1;
    border-top-right-radius: 100%;
}


.eventail{
    position: absolute;
    top: 40.89%;
    left: 46.87%;
    height: 8.3%;
    width: 5.55%;
}

.eventailImg, .evantailImgFliped{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 1000;
}

.resineContainer{
    position: absolute;
    top: 50.5%;
    left: 37%;
    width: 16%;
    height: 5.5%;
    transform: rotate(22deg);
    z-index: 1100;
}

.unDisplayed{
    display: none;
}

.manteauContainer{
    position: absolute;
    top: 46.72%;
    left: 38.53%;
    width: 7.40%;
    height: 16.06%;
}

.manteauImg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 1000;
}
.freeBoxContainer{
    position: absolute;
    top: 40.36%;
    left: 50.64%;
    height: 4.48%;
    width: 8%;
}
.manetteContainer{
    position: absolute;
    top: 49.13%;
    left: 57.84%;
    height: 2.31%;
    width: 3.12%;
}