$redFree : #c00000; 
$grey : #576065;


.SdbContainer{
    background-image: url('../../assets/room/sdb/salleDeBain.jpg');
    position: relative;
}

.doorSdbToPalier{
    position: absolute;
    top: 17.78%;
    left: 87.90%;
    width: 8.14%;
    height: 69.6%;
}

.armoireContainer{
    position: absolute;
    top: 12.87%;
    left: 59.74%;
    height: 27.41%;
    width: 11.02%;
}
.armoireSdb{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 280px;
    z-index: 1000;
}

.modalCodeSdbContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 70%;
    background-color: white;
    border-radius: 15px;
    text-align: center;

    p{
        color: $redFree;
        font-weight: 600;
        font-size: 25px;
        position: relative;
        top: 15%;
    }
    span{
        position: relative;
        top: 30%;
        font-style: italic;
        font-size: 18px;
        color: $grey;
    }
    .inputContainer{

        display: flex;
        justify-content: space-between;
        position: relative;
        top: 20%;
        margin: auto;
        width: 35%;
        input{
            height: 150px;
            width: 80px;
            background-image: linear-gradient(white, #d8cfca);
            border: 1px solid #d8cfca;
            border-radius: 30px;
   
        }
        input[type="text" i] {
            font-size: 40px;
            text-align: center;
            color: $redFree;
        }
        input:focus-visible {
            outline: none;
          }
        .blueInput{
            border: 1px solid blue ;
        }
        .yellowInput{
            border:  1px solid yellow;
        }
        .redInput{
            border: 1px solid red;
        }
    }

}


.parfumContainer{
    position: absolute;
    top:  37%;
    left: 53%;
    height: 6.76%;
    width: 5.4%;
}

.magazineHContainer{
    position: absolute;
    top : 58.3%;
    left: 60%;
    height: 7.7%;
    width: 7.1%;
    transform: skew(-10deg);
    transform: rotate(10deg);
}

.magazineFContainer{
    position: absolute;
    top: 67.4%;
    left: 59.5%;
    width: 6.62%;
    height: 8.37%;
    z-index: 60;
}
.magazineContainer{
    position: absolute;
    top: 62.4%;
    left: 58.5%;
    width: 5%;
    height: 8.37%;
    z-index: 50;
    transform: skew(10deg);
}