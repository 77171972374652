$redFree : #c00000; 
$grey : #576065;

.popUpAddictionContainer{
    position: absolute;
    max-height: 80%;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    display: flex;
    scrollbar-width: thin;
    z-index: 3000;
    
    
        
    .redCross{
        position: absolute;
        top: 5%;
        right: 5%;
        width: 20px;
        cursor: pointer;
    }

    .popUpAddictionContent{
        margin: 2em;
        padding: 2em;
        font-size: 16px;    
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        color: $grey;
        
        *{
            text-align: justify;
            line-height: normal;
        }
    }

    .headerPopUp{
        .imgPopUpAddiction{
            max-width: 120px;
            max-height: 120px;
            float: left;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-right: 3em;
            margin-bottom: 1em;
            margin-left: -1em;
        }
        display: flex;
        
    }
    .popUpAddictionContent::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background-color: white; /* or add it to the track */
        color: $grey;
        }
    .popUpAddictionContent::-webkit-scrollbar-thumb {
        background: $redFree;
    }

    h1{
        color: $redFree;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
        margin: 0.5em 0;
    }
    h2{
        font-size: 18px;
        font-weight: 500;
        margin: 0;
    }
    p{
        font-size: 17px;
    }
    .redMessage{
        display: flex;
        .handShakeContainer{
            margin-right: 1em;
            img{
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    h3{
        color: $redFree;
    }
    hr{
        border-top: 1px solid $grey;
    }
    .sources{
        p{
            font-size: 13px!important;        
            margin: 0.2em 0 ;
        }
        
    }
    .imgMedicaments{
        text-align: center;
    }
}

