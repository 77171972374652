.salonContainer{
    position: relative;
    background-image: url('../../assets/room/salon/salon.jpg');
}
.doorToCuisine{
    position: absolute;
    left: 77.7%;
    top: 5.7%;
    height: 45.8%;
    width: 14.8%;
}

.teleContainer{
    position:absolute ;
    top: 10.4%;
    left: 35.8%;
    width: 33.7%;
    height: 22.3%;
}
.notDisplay{
    display: none;
}

.teleFree{
    position: absolute;
    top: 10.4%;
    left: 35.8%;
    height: 23%;
    width: 33.7%;
}

.carnetContainer{
    position: absolute;
    top: 56.46%;
    left: 47.5%;
    height: 3.61%;
    width: 4.65%;
}

.carnet{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
}
.tiroirLeftContainerSalon{
    position: absolute;
    top: 45.50%;
    left: 33.3%;
    height: 6.56%;
    width: 9.13%;
}
.tiroirLeftSalon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
}

.tiroirRightContainerSalon{
    position : absolute;
    top: 45.38%;
    left: 65.14%;
    height: 6.74%;
    width: 6.47%;
}

.tiroirRightSalon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
}

.pcContainerSalon{
    position: absolute;
    top: 49.12%;
    left: 60.8%;
    width: 5.58%;
    height: 11.37%;
}
.pcAddictionJeux{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    z-index: 1000;
}