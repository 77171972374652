.popUpAddictionContainer {
  position: absolute;
  max-height: 80%;
  width: 90%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  scrollbar-width: thin;
  z-index: 3000;
}

.popUpAddictionContainer .redCross {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 20px;
  cursor: pointer;
}

.popUpAddictionContainer .popUpAddictionContent {
  margin: 2em;
  padding: 2em;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  color: #576065;
}

.popUpAddictionContainer .popUpAddictionContent * {
  text-align: justify;
  line-height: normal;
}

.popUpAddictionContainer .headerPopUp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popUpAddictionContainer .headerPopUp .imgPopUpAddiction {
  max-width: 120px;
  max-height: 120px;
  float: left;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-right: 3em;
  margin-bottom: 1em;
  margin-left: -1em;
}

.popUpAddictionContainer .popUpAddictionContent::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: white;
  /* or add it to the track */
  color: #576065;
}

.popUpAddictionContainer .popUpAddictionContent::-webkit-scrollbar-thumb {
  background: #c00000;
}

.popUpAddictionContainer h1 {
  color: #c00000;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 800;
  margin: 0.5em 0;
}

.popUpAddictionContainer h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.popUpAddictionContainer p {
  font-size: 17px;
}

.popUpAddictionContainer .redMessage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popUpAddictionContainer .redMessage .handShakeContainer {
  margin-right: 1em;
}

.popUpAddictionContainer .redMessage .handShakeContainer img {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.popUpAddictionContainer h3 {
  color: #c00000;
}

.popUpAddictionContainer hr {
  border-top: 1px solid #576065;
}

.popUpAddictionContainer .sources p {
  font-size: 13px !important;
  margin: 0.2em 0;
}

.popUpAddictionContainer .imgMedicaments {
  text-align: center;
}
