.sensiMessageContainer::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: white;
  /* or add it to the track */
  color: #576065;
}

.sensiMessageContainer::-webkit-scrollbar-thumb {
  background: #c00000;
}

.sensiMessageContainer {
  width: 100vw;
  min-height: 100vh;
  max-height: 80vh;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sensiMessageContainer .logoFreeContainer {
  float: right;
  margin: 0.8em;
}

.sensiMessageContainer .sensiContainer {
  width: 80%;
  margin: 2em auto;
}

.sensiMessageContainer .sensiContainer .mainTitle {
  font-size: 35px;
  color: #c00000;
  margin: 2.5em 0 2em 0;
  font-weight: 400;
}

.sensiMessageContainer .sensiContainer hr {
  border: 4px solid #dfdfdf;
}

.sensiMessageContainer .sensiContent {
  margin: 3em 0;
}

.sensiMessageContainer .sensiContent .imgContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  line-height: 20px;
}

.sensiMessageContainer .sensiContent .imgContainer .imgContent {
  min-width: 180px;
}

.sensiMessageContainer .sensiContent .imgContainer img {
  max-width: 170px;
  max-height: 110px;
  margin-right: 2em;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.sensiMessageContainer .sensiContent .imgContainer h1 {
  color: #cc0001;
  font-weight: 700;
  margin: 0;
}

.sensiMessageContainer .sensiContent .imgContainer h2 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  font-style: italic;
  color: #576065;
  text-align: justify;
}

.sensiMessageContainer .sensiContent .textSensi h3 {
  text-align: center;
  font-style: italic;
  color: #c00000;
  width: 60%;
  margin: auto;
  font-weight: 700;
  font-size: 20px;
}

.sensiMessageContainer .sensiContent .textSensi p {
  font-size: 18px;
  padding: 0.5em;
  font-weight: 300;
  text-align: justify;
}

.sensiMessageContainer .sensiContent .textSensi .imgMedicaments {
  text-align: center;
  margin: 0;
  padding: 0;
}

.sources p {
  font-size: 13px !important;
  margin: 0.2em 0;
  color: #576065;
  text-align: justify;
}

.winLooseScreenContainer {
  height: 100vh;
  width: 100%;
  background-color: #e9e9e9;
}

.winLooseScreen {
  width: 100%;
  text-align: center;
  position: relative;
}

.winLooseScreen img {
  max-width: 80%;
  max-height: 100%;
}

.winLooseScreen .btnMsgWinContainer {
  position: absolute;
  top: 82.1%;
  width: 50%;
  left: 49.9%;
  width: 36.4%;
  height: 8.7%;
  border-radius: 27px;
  cursor: pointer;
}

.winLooseScreen .btnMsgLooseContainer {
  position: absolute;
  top: 72.7%;
  width: 50%;
  left: 49.9%;
  width: 36.4%;
  height: 8.3%;
  border-radius: 27px;
  cursor: pointer;
}

.winLooseScreen .btnPlayAgain {
  position: absolute;
  top: 82.1%;
  width: 50%;
  left: 49.9%;
  width: 36.4%;
  height: 8.7%;
  border-radius: 27px;
  cursor: pointer;
}

.playAgain {
  width: 150px;
  background-color: #c00000;
  padding: 0.5em 4em;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  margin: 2em auto;
}
