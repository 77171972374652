.bureauContainer {
  background-image: url("../../assets/room/bureau/bureau.jpg");
  position: relative;
}

.notDisplay {
  display: none;
}

.tiroirBureauContainer {
  position: absolute;
  top: 63.5%;
  left: 67.9%;
  height: 4.94%;
  width: 10.36%;
}

.tiroirBureau {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 400px;
}

.vaseBureauContainer {
  position: absolute;
  top: 77.6%;
  left: 16.75%;
  height: 9.6%;
  width: 2.7%;
}

.papierPoemeContainer {
  position: absolute;
  top: 15%;
  left: 44.5%;
  width: 10%;
  height: 8%;
}

.afficheContainer1 {
  position: absolute;
  top: 21.8%;
  left: 46.8%;
  height: 22.1%;
  width: 14.6%;
}

.afficheContainer2 {
  position: absolute;
  top: 44.7%;
  left: 46.3%;
  width: 8.66%;
  height: 12.85%;
}

.postItRoseContainer {
  position: absolute;
  top: 58.3%;
  left: 54.81%;
  height: 2.74%;
  width: 1.7%;
}

.postItJauneContainer {
  position: absolute;
  top: 56.1%;
  left: 75.1%;
  width: 2.1%;
  height: 3.5%;
}

.pcContainer {
  position: absolute;
  top: 44.9%;
  left: 55.6%;
  height: 13.6%;
  width: 16.75%;
}
