.popUpContainer{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);
    z-index: 2500;

    img{
        position: relative;
        max-height: 90%;
        max-width: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
