$redFree : #c00000; 
$grey : #576065;

.PalierContainer{
    background-image: url('../../assets/room/palier/palier.jpg');
    position: relative;
}

.doorPalierToSdb{   
    position: absolute;
    top: 30%;
    left: 88%;
    height: 62.13%;
    width: 6.17%;
}

.doorToChambre{
    position: absolute;
    top: 28.9%;
    left: 35.41%;
    width: 7.72%;
    height: 61.9%;
}

.doorToBureau{
    position: absolute;
    top: 30.5%;
    left: 56%;
    height: 59%;
    width: 19.1%;
}

.modalCodeBureauContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 70%;
    background-color: white;
    border-radius: 15px;
    text-align: center;

    p{
        color: $redFree;
        font-weight: 600;
        font-size: 25px;
        position: relative;
        top: 15%;
    }
    .inputContainer{

        display: flex;
        justify-content: space-between;
        position: relative;
        top: 20%;
        margin: auto;
        width: 90%;

        img{
            width: 80px;
            margin: 0.9em;
        }
        input{
            height: 150px;
            width: 80px;
            background-image: linear-gradient(white, #d8cfca);
            border: 1px solid #d8cfca;
            border-radius: 30px;
   
        }
        input[type="text" i] {
            font-size: 40px;
            text-align: center;
            color: $redFree;
        }
        input:focus-visible {
            outline: 1px dashed $redFree;
          }
    }

}