$redFree : #c00000; 
$grey : #576065;

.chambreContainer{
    background-image: url('../../assets/room/chambre/chambre.jpg');
    position: relative;
}

.valise{
    position: absolute;
    top: 63.7%;
    left: 54.3%;
    height: 23.15%;
    width: 13.6%;
}

.valiseImg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    z-index: 1000;
}

.unDisplayed{
    display: none;
}

.tapisChambreContainer{
    position: absolute;
    top: 55.6%;
    left: 85%;
    height: 20.6%;
    width: 3.6%;
}

.priseChambreContainer{
    position: absolute;
    top: 49.60%;
    left: 82%;
    height: 6.5%;
    width: 2.23%;
}

.carteChambreContainer{
    position: absolute;
    top: 25.9%;
    left : 73%;
    height: 9.9%;
    width: 4.2%;
    z-index: 2500;
}

.tiroirLeftContainerChambre{
    position: absolute;
    top: 56.13%;
    left: 24.92%;
    height: 6.21%;
    width: 11.04%;
}
.tiroirRightContainerChambre{
    position : absolute;
    top: 59.75%;
    left: 73.44%;
    height: 3.90%;
    width: 9.13%;
}
.photoChambreContainer{
    position: absolute;
    top: 49.13%;
    left: 75.5%;
    width: 5.86%;
    height: 8.40%;
}
.barreChambreContainer{
    position: absolute;
    top: 42.5%;
    left: 71%;
    width: 8%;
    height: 5.9%;
    z-index: 2500;
    transform: rotate(-30deg);
}

.sexeAddictionContainer1{
    position: absolute;
    top: 48%;
    left: 58%;
    height: 15%;
    width: 4.7%;
    z-index: 2500;
    transform: rotate(13deg);
}
.sexeAddictionContainer2{
    position: absolute;
    top: 48%;
    left: 67%;
    height: 8.5%;
    width: 10.2%;
    z-index: 2500;
    transform: rotate(28deg);
}

.modalCodeChambreContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 70%;
    background-color: white;
    border-radius: 15px;
    text-align: center;



    p{
        color: $redFree;
        font-weight: 600;
        font-size: 25px;
        position: relative;
        top: 15%;
    }
    span{
        position: relative;
        top: 30%;
        font-style: italic;
        font-size: 18px;
        color: $grey;
    }
    .inputContainer{

        display: flex;
        justify-content: space-between;
        position: relative;
        top: 20%;
        margin: auto;
        width: 35%;

        input{
            height: 150px;
            width: 80px;
            background-image: linear-gradient(white, #d8cfca);
            border: 1px solid #d8cfca;
            border-radius: 30px;
   
        }
        input[type="text" i] {
            font-size: 40px;
            text-align: center;
            color: $redFree;
        }
        input:focus-visible {
            outline: 1px dashed $redFree;
          }
    }

}