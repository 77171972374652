.PalierContainer {
  background-image: url("../../assets/room/palier/palier.jpg");
  position: relative;
}

.doorPalierToSdb {
  position: absolute;
  top: 30%;
  left: 88%;
  height: 62.13%;
  width: 6.17%;
}

.doorToChambre {
  position: absolute;
  top: 28.9%;
  left: 35.41%;
  width: 7.72%;
  height: 61.9%;
}

.doorToBureau {
  position: absolute;
  top: 30.5%;
  left: 56%;
  height: 59%;
  width: 19.1%;
}

.modalCodeBureauContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  height: 70%;
  background-color: white;
  border-radius: 15px;
  text-align: center;
}

.modalCodeBureauContainer p {
  color: #c00000;
  font-weight: 600;
  font-size: 25px;
  position: relative;
  top: 15%;
}

.modalCodeBureauContainer .inputContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  top: 20%;
  margin: auto;
  width: 90%;
}

.modalCodeBureauContainer .inputContainer img {
  width: 80px;
  margin: 0.9em;
}

.modalCodeBureauContainer .inputContainer input {
  height: 150px;
  width: 80px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(white), to(#d8cfca));
  background-image: linear-gradient(white, #d8cfca);
  border: 1px solid #d8cfca;
  border-radius: 30px;
}

.modalCodeBureauContainer .inputContainer input[type="text" i] {
  font-size: 40px;
  text-align: center;
  color: #c00000;
}

.modalCodeBureauContainer .inputContainer input:focus-visible {
  outline: 1px dashed #c00000;
}
