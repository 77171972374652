.buttonAcceuil {
  color: white;
  background-color: #c00000;
  padding: 0.8em 1.8em;
  border-radius: 10px;
  border: none;
  font-size: 21px;
  font-weight: 700;
  margin-top: 2em;
  cursor: pointer;
}

.btnSecondPageAcceuil {
  margin-top: 0.5em !important;
  display: inline-block;
  margin: auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 10%;
}

.firstPageAcceuil {
  text-align: center;
}

.firstPageAcceuil .mentions {
  position: absolute;
  top: 92%;
  right: 3%;
  font-size: 17px;
  font-weight: 500;
  color: #576065;
  text-decoration: underline;
}

.firstPageAcceuil .logo {
  width: 700px;
  margin: auto;
}

.firstPageAcceuil h1 {
  color: #c00000;
  font-weight: 700;
  font-size: 24px;
}

.firstPageAcceuil h2 {
  color: #576065;
  font-weight: 400;
}

.secondPageContainer {
  height: 100vh;
  width: 100%;
  background-color: #f7f7f7;
}

.secondPage {
  width: 80%;
  text-align: center;
  position: relative;
  margin: auto;
}

.secondPage img {
  max-width: 80%;
  max-height: 100%;
}

.secondPage .btnStartContainer {
  position: absolute;
  top: 82.1%;
  width: 50%;
  left: 61.2%;
  width: 18.5%;
  height: 8.9%;
  border-radius: 10px;
  cursor: pointer;
}

.consignePage {
  text-align: center;
}

.consignePage h1 {
  color: #c00000;
  font-size: 24px;
  font-weight: 700;
}

.consignePage button {
  color: white;
  background-color: #c00000;
  padding: 0.6em 2.8em;
  border-radius: 10px;
  border: none;
  font-size: 21px;
  font-weight: 700;
  margin-top: 2em;
  cursor: pointer;
}

.consignePage .strongP {
  color: #c00000;
  font-size: 18px;
  font-weight: 700;
  margin-top: 1.5em;
  line-height: 30px;
}

.logo {
  width: 500px;
  margin: auto;
}

.mentionsPage {
  text-align: center;
  padding: 3em;
  width: 70%;
  margin: auto;
}

.mentionsPage h1 {
  color: #c00000;
  margin: 0.5em 0 1em 0;
  font-size: 24px;
}

.mentionsPage p {
  color: #576065;
}

.mentionsPage button {
  margin-top: 1em;
  background-color: #c00000;
  padding: 0.5em 3em;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .logo {
    width: 300px !important;
  }
}

p {
  font-size: 17px;
  font-weight: 500;
  color: #576065;
  max-width: 60%;
  margin: auto;
  line-height: 28px;
}
