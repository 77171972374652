.cuisineContainer{
    background-image: url('../../assets/room/cuisine/cuisine.jpg');
    position: relative;
}

.phoneCuisine{
    position: absolute;
    top: 48.9%;
    left: 88.1%;
    width: 3.22%;
    height: 2.5%;
}

.frigoUp{
    position: absolute;
    top: 20.1%;
    left: 59.1%;
    height: 29.3%;
    width: 15.2%;

}

.frigoDown{
    position: absolute;
    top: 49.6%;
    left: 60.3%;
    height: 20.2%;
    width: 14.4%;
}

.frigoUpImg{
    position: absolute;
    top: 20.1%;
    left: 60%;
    height: 49.6%;
}

.frigoDownImg{
    position: absolute;
    top: 20.1%;
    left: 60%;
    height: 49.6%;
}



.unDisplayed{
    display: none;
}

.phoneOpenImg, .phoneAddictionImg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    z-index: 1000;
}

.tempsEcranContainer{
    position: absolute;
    top: 45.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 6%;
    width: 18%;
    z-index: 1000;
}

.poisChicheContainer{
    position: absolute;
    top: 13.25%;
    left: 23.9%;
    height: 4.55%;
    width: 2%;

}

.avoineContainer{
    position: absolute;
    top: 13%;
    left: 16.2%;
    height: 5%;
    width: 2%;

}

.sonContainer{
    position: absolute;
    top: 13%;
    left: 13.77%;
    width: 2.35%;
    height: 4.8%;

}

.prunneauxContainer{
    position: absolute;
    top: 20.6%;
    left: 15.9%;
    height: 2.8%;
    width: 3.33%;

}

.noixContainer{
    position: absolute;
    top: 15.6%;
    left: 18.2%;
    height: 2.28%;
    width: 2.86%;

}

.lentilleContainer{
    position: absolute;
    top: 18.9%;
    left: 23.5%;
    height: 4.5%;
    width: 1.86%;

}

.haricotsContainer{
    position: absolute;
    top: 18.74%;
    left: 25.41%;
    width: 1.9%;
    height: 4.62%;

}

.postItContainer{
    position: absolute;
    top: 38.76%;
    left: 63%;
    width: 3.42%;
    height: 4.5%;
    z-index: 1000;

}

