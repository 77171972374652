.popUpContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 2500;
}

.popUpContainer img {
  position: relative;
  max-height: 90%;
  max-width: 70%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
